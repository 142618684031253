import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import CollapsedListContext from "module/collapsedList/context/CollapsedListContext";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { isMaintenanceTaskProcessStepToolsAndEquipmentAvailable } from "module/maintenanceTaskProcessStep/helper/isMaintenanceTaskProcessStepToolsAndEquipmentAvailable";
import { isMaintenanceTaskProcessStepActionPerformedAvailable } from "module/maintenanceTaskProcessStep/helper/isMaintenanceTaskProcessStepActionPerformedAvailable";
import { isMaintenanceTaskProcessStepCheckedResultAvailable } from "module/maintenanceTaskProcessStep/helper/isMaintenanceTaskProcessStepCheckedResultAvailable";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import {
  MaintenanceTaskProcessStepCheckResult,
  MaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";
import MaintenanceTaskProcessStepListRow from "module/maintenanceTaskProcessStep/enrichedComponent/tableRow/MaintenanceTaskProcessStepListRow";
import { isMaintenanceTaskProcessStepQuickFixAvailable } from "module/maintenanceTaskProcessStepQuickFix/helper/isMaintenanceTaskProcessStepQuickFixAvailable";
import { isMaintenanceTaskProcessStepCorrectiveFixAvailable } from "module/maintenanceTaskProcessStepQuickFix/helper/isMaintenanceTaskProcessStepCorrectiveAvailable";

const MaintenanceTaskProcessStepListRowContainer: React.FC = () => {
  const { areAllExpanded } = useContext(CollapsedListContext);
  const { entity: maintenanceTaskEntity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = maintenanceTaskEntity as MaintenanceTaskInterface;

  const isActionPerformedAvailable = useMemo(
    () => isMaintenanceTaskProcessStepActionPerformedAvailable(maintenanceTask),
    [maintenanceTask]
  );

  const isCheckedResultAvailable = useMemo(
    () => isMaintenanceTaskProcessStepCheckedResultAvailable(maintenanceTask),
    [maintenanceTask]
  );

  const isToolAndEquipmentAvailable = useMemo(
    () =>
      isMaintenanceTaskProcessStepToolsAndEquipmentAvailable(maintenanceTask),
    [maintenanceTask]
  );

  const { entity } = useContext(MaintenanceTaskProcessStepContext);
  const processStep = entity as MaintenanceTaskProcessStepInterface;

  const [isOpen, setIsOpen] = useState(areAllExpanded);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const isQuickFixAvailable = useMemo(
    () =>
      isMaintenanceTaskProcessStepQuickFixAvailable(
        maintenanceTask,
        processStep
      ),
    [maintenanceTask, processStep]
  );

  const isCorrectiveFixAvailable = useMemo(
    () =>
      isMaintenanceTaskProcessStepCorrectiveFixAvailable(
        maintenanceTask,
        processStep
      ),
    [maintenanceTask, processStep]
  );

  useEffect(() => {
    setIsOpen(areAllExpanded);
  }, [areAllExpanded]);

  const isHighlighted = [
    "safety",
    "lockout",
    "Final Verification",
    "final verification",
  ].includes(processStep.type);

  const isCompleted =
    processStep.checkResult !== MaintenanceTaskProcessStepCheckResult.not_set;

  return (
    <MaintenanceTaskProcessStepListRow
      {...{
        key: processStep.id,
        processStep,
        isOpen,
        toggle,
        isCheckedResultAvailable,
        isActionPerformedAvailable,
        isQuickFixAvailable,
        isCorrectiveFixAvailable,
        isToolAndEquipmentAvailable,
        isHighlighted,
        isCompleted,
      }}
    />
  );
};
export default React.memo(MaintenanceTaskProcessStepListRowContainer);
